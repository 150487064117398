import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader';
import { graphql } from 'gatsby'
import Markdown from 'react-markdown';
//import htmlParser from 'react-markdown/plugins/html-parser'
//import ClientSection from '../components/clients'

const PageTemplate = ({data}) => {
    return (
        <Layout>
            <SEO title={data.strapiPages.page_title} />
            <SubHeader title={data.strapiPages.page_title}/>
            <div id="blog-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            <div className="blog-post">
                                <div className="post-thumb">
                                    <img src={data.strapiPages.image ? data.strapiPages.image.childImageSharp.fluid.src : null} alt=""/>
                                </div>
                                <div className="post-content">
	                                <h3>{data.strapiPages.page_title}</h3>
                                  <Markdown source={data.strapiPages.page_content} escapeHtml={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PageTemplate;

export const query = graphql`
  query PageTemplate($id: String!) {
    strapiPages(id: { eq: $id }) {
      id
      page_title
      page_excerpt
      page_content
      image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
` 